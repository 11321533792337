/* eslint-disable */
import React, { ReactNode, useCallback, useState } from 'react';
import { Button, Modal, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadFile } from 'antd/es/upload/interface';
import { DukeDragger } from '../../component/DukeDragger';

interface Props {
    visible: boolean;
    fileList: UploadFile[];
    onSaveFileList: (fileList: UploadFile[]) => void;
    onClose: any;
}

export default function UploadFileModal ({ visible, onSaveFileList, fileList, onClose }: Props) {
    const { t } = useTranslation();
    const [files, setFiles] = useState<UploadFile[]>(fileList || []);

    const sendFileList = useCallback((newFileList) => {
        onSaveFileList(newFileList);
        onClose();
    }, [onSaveFileList, onClose],);

    const draggerProps: UploadProps = {
        accept: '.xls, .xlsx, .xlsm, .xlsb, .csv, .jpeg, .jpg, .png',
        multiple: true,
        fileList: files,
        action: (fileToAdd) => {
            setFiles(fls => [fileToAdd, ...fls]);
        },
        onRemove: (fileToRemove) => {
            setFiles((fls) =>
              fls.filter((file) => file.name !== fileToRemove.name)
            );
        }
    };

    const footer: ReactNode = (<>
        <Button type="text" onClick={() => {
            setFiles([]);
            sendFileList([]);
        }}>
            {t('common.clear')}
        </Button>
        <Button type="primary" onClick={() => sendFileList(files)}>
            {t('common.done')}
        </Button>
    </>);

    return (<>
        {visible && (<Modal
          title={t('dukeChat.searchInUploadedFile.title')}
          visible={visible}
          onCancel={onClose}
          width="40%"
          bodyStyle={{ maxHeight: '40%' }}
          footer={footer}
        >
            <DukeDragger {...{ draggerProps, hint: t('dukeChat.searchInUploadedFile.hint') }} />
        </Modal>)}
    </>);
}
